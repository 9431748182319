// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select_amwtB input{--tw-border-opacity:1!important;--tw-shadow:0 1px 2px 0 rgba(0,0,0,.05)!important;--tw-shadow-colored:0 1px 2px 0 var(--tw-shadow-color)!important;background-color:transparent!important;border-radius:9999px!important;border-width:3px!important;box-shadow:0 0 transparent,0 0 transparent,0 1px 2px 0 rgba(0,0,0,.05)!important;box-shadow:var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow)!important;height:3rem!important}.select_amwtB input,.select_amwtB input:focus{border-color:#191919!important;border-color:rgba(25,25,25,var(--tw-border-opacity))!important}.select_amwtB input:focus{--tw-border-opacity:1!important;--tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)!important;--tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color)!important;box-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color),var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color),0 0 transparent!important;box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow,0 0 transparent)!important;outline:2px solid transparent!important;outline-offset:2px!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "select_amwtB"
};
module.exports = ___CSS_LOADER_EXPORT___;
