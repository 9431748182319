export default {
  mounted() {
    window.initMap = this.initMap;
    this.loadScript();
  },
  // computed: {
  //   src() {
  //     const src = require(`/icons/map_pin.svg?raw`)
  //     return src
  //   }
  // },
  methods: {
    loadScript() {
      if (window.google && window.google.maps) {
        this.initMap();
        return;
      }

      const script = document.createElement("script");
      script.onload = function () {
        if (!window.google && !window.google.maps)
          return void (console.error("no google maps script included"));
      };
      script.async = true;
      script.defer = true;
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyApKB7mypcHmeyJvGb_sthNylJXbiytkz0&loading=async&callback=initMap&v=weekly&libraries=marker";
      document.getElementsByTagName("head")[0].appendChild(script);
    },
    initMap() {
      const myLatLng = {lat: 42.6919776, lng: 23.3590634};
      const mapOptions = {
        panControl: true,
        zoomControl: true,
        mapTypeControl: false,
        fullscreenControl: false,
        keyboardShortcuts: false,
        scaleControl: true,
        streetViewControl: false,
        overviewMapControl: false,
        rotateControl: false
      }
      const map = new window.google.maps.Map(document.getElementById("map"), {
        zoom: 12,
        center: myLatLng,
        mapId: '22462e6a639be892',
        ...mapOptions
      });
      const filteredMarkers = []

      this.markers.forEach(marker => {
        const parser = new DOMParser();
        // A marker with a custom inline SVG.
        const pinSvgString = require(`/static/icons/map_pin.svg?raw`)
        const pinSvg = parser.parseFromString(
          pinSvgString,
          "image/svg+xml",
        ).documentElement;
        filteredMarkers.push(new window.google.maps.marker.AdvancedMarkerElement({
          position: {
            lat: marker.attributes.location.lat,
            lng: marker.attributes.location.lng
          },
          map,
          title: marker.attributes.title,
          content: pinSvg,
        }));
        marker.details = 'custom property';
      })

      filteredMarkers.forEach((marker, index) => {
        this.addInfoWindow(marker,
          '<div class="info-window-content !bg-black text-eddy-body">' +
          '<h2 class="mb-1">' + marker.title + '</h2>' +
          '<p class="mb-1 text-base">' + this.markers[index].attributes.details + '</p>' +
          '<a href="https://maps.google.com/?q=' + marker.position.lat + ' ,' + marker.position.lng + '" class="underline text-colored" target="new">View in Maps</a>' +
          '</div>');
      })
    },
    addInfoWindow(marker, message) {
      const infoWindow = new window.google.maps.InfoWindow({
        content: message
      });

      window.google.maps.event.addListener(marker, 'click', function () {
        infoWindow.open(map, marker);
      });
    }
  }
};
