
export default {
  props: {
    startValue: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      value: ''
    }
  },
  created() {
    this.value = this.startValue;
  }
};
