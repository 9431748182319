
import googleMapsMixin from '@/mixins/googleMapsMixin';

export default {
  name: "ThreeColumnsMap",
  props: {
    data: Object,
    markers: Array,
  },
  mixins: [googleMapsMixin],
  mounted() {
    if (this.$device.isMobile) {
      this.initAnimationSequence();
    }
  },
  methods: {
    initAnimationSequence() {
      const title = this.$refs['title'];
      const container = this.$refs['container'];
      // Retrieve the height of the title element
      const titleHeight = title.clientHeight;
      const endValue = `bottom-=${titleHeight}px 68px`;

      this.$ScrollTrigger.create({
        trigger: container,
        scrub: 0.5,
        start: "top 68px",
        end: endValue,
        pin: title,
        scroller: window.matchMedia("(max-width: 768px)").matches ? null : "[data-scroll-container]",
        anticipatePin: 1,
        // markers: true,
      });
    },
  }
}
